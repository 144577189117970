<template>
   <v-container class="flag-back">
      <div class="d-flex justify-space-between">
         <div class="text-h4">Rekap</div>
         <v-menu v-if="type==='daily'" v-model="show_picker[0]" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px" >
            <template v-slot:activator="{ on }">
               <v-text-field style="max-width:140px" class="mr-2" v-model="date" label="Date" prepend-inner-icon="mdi-calendar-range" readonly v-on="on" />
            </template>
            <v-date-picker v-model="date" @input="show_picker[0]=false;getData()" />
         </v-menu>
         <template v-else-if="type==='monthly'">
            <v-menu v-model="show_picker[1]" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px" >
               <template v-slot:activator="{ on }">
                  <v-text-field style="max-width:140px" class="mr-2" v-model="from_date" label="Date" prepend-inner-icon="mdi-calendar-range" readonly v-on="on" />
               </template>
               <v-date-picker v-model="from_date" @input="show_picker[1]=false" />
            </v-menu>
            <v-menu v-model="show_picker[2]" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px" >
               <template v-slot:activator="{ on }">
                  <v-text-field style="max-width:140px" class="mr-2" v-model="to_date" label="Date" prepend-inner-icon="mdi-calendar-range" readonly v-on="on" />
               </template>
               <v-date-picker v-model="to_date" @input="show_picker[2]=false" />
            </v-menu>
         </template>
         <v-select style="max-width:120px" :items="types" @change="getData" v-model="type" />
      </div>
      <v-row justify="space-around">
         <v-col cols="12" sm="4" lg="3">
            <widget
               title="Transaksi"
               color="#5fd0b1"
               color2="#64b3c1"
               icon="mdi-chart-timeline"
               :data="transaction"
               :act="()=>getDetail('customer')"
               :active="view_detail==='customer'"
            />
         </v-col>
         <v-col cols="12" sm="4" lg="3">
            <widget
               title="Terjual"
               color="#64b3c1"
               color2="#7c71d5"
               icon="mdi-chart-timeline"
               :data="product"
               :act="()=>getDetail('sold')"
               :active="view_detail==='sold'"
            />
         </v-col>
         <v-col cols="12" sm="4" lg="3">
            <widget
               title="Omzet"
               color="#9e69d2"
               color2="#e669ab"
               icon="mdi-cash-multiple"
               :data="omzet"
            />
         </v-col>
      </v-row>
      <v-row justify="space-between">
         <v-col cols="12">
            <v-card>
               <v-row class="mx-3 justify-space-between">
                  <v-col cols="6" sm="5">
                     Detail {{view_detail}}
                  </v-col>
                  <v-col cols="6" sm="3" class="text-right">
                     <v-icon title="previus page" @click="page--" :disabled="page===1">mdi-arrow-left-circle</v-icon>
                     <span class="px-3">Page {{page}} of {{last_page}}</span>
                     <v-icon title="next page" @click="page++" :disabled="page===last_page">mdi-arrow-right-circle</v-icon>
                  </v-col>
               </v-row>
               <v-simple-table>
                  <template v-slot:default>
                     <thead>
                        <tr>
                           <th v-if="view_detail==='sold'" :class="sort==='name'? desc?'desc':'asc':''" @click="sortBy('name')">Produk</th>
                           <th v-else-if="view_detail==='customer'" :class="sort==='name'? desc?'desc':'asc':''" @click="sortBy('name')">Pelanggan</th>
                           <th class="text-right" :class="sort==='price'? desc?'desc':'asc':''" @click="sortBy(`price`)">Nilai</th>
                           <th class="text-right" :class="sort===`quantity`? desc?'desc':'asc':''" @click="sortBy(`quantity`)">Item</th>
                           <template v-if="view_detail==='sold'">
                              <th class="text-right" :class="sort==='price'? desc?'desc':'asc':''" @click="sortBy(`price`)">Harga</th>
                              <!-- <th class="text-center" :class="sort==='id'? desc?'desc':'asc':''" @click="sortBy('id')">#</th> -->
                           </template>
                           <template v-else-if="view_detail==='customer'">
                              <th class="text-right" :class="sort==='product'? desc?'desc':'asc':''" @click="sortBy(`product`)">Product</th>
                           </template>
                        </tr>
                     </thead>
                     <tbody>
                        <tr v-for="(p,i) in list" :key="i">
                           <td>{{ p.name }}</td>
                           <td class="text-right">{{ p.value && p.value.toLocaleString('id-ID') }}</td>
                           <td class="text-right">{{ p.quantity }}</td>
                           <template v-if="view_detail==='sold'">
                              <td class="text-right">{{ p.price }}</td>
                              <!-- <td class="text-center">{{ p.transaction }}</td> -->
                           </template>
                           <template v-else-if="view_detail==='customer'">
                              <td class="text-right">{{ p.product }}</td>
                           </template>
                        </tr>
                     </tbody>
                  </template>
               </v-simple-table>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>
<script>
import api from '../api';
import {format, subDays} from 'date-fns';
import widget from '../components/widget.vue'
export default {
  components: { widget },
   data: () => ({
      bln: ['','Jan','Feb','Mar','Apr','Mei','Jun','Jul','Agt','Sep','Okt','Nov','Des'],
      types: [{value: 'daily', text: 'Harian'},{value: 'monthly', text: 'Bulanan'}],
      type: 'daily',
      show_picker: [],
      view_detail: 'sold',
      date: null,
      from_date: null,
      to_date: null,
      list: [],
      filter: {},
      page: 1,
      last_page: 1,
      total: 0,
      sort: null,
      desc: false,
      loading: false,
      omzet: [{value: 0,label:'Cash'}],
      product: [{value: 0,label:'Produk'},{value: 0,label:'Item'}],
      transaction: [{value: 0,label:'Transaksi'},{value: 0,label:'Pelanggan'}],
   }),
   mounted() {
      this.date = format(new Date(),'yyyy-MM-dd');
      this.getData();
   },
   methods: {
      getData() {
         let url = 'report?';
         if(this.type === 'daily') {
            url += `date=${this.date}`;
         } else {
            if(!this.from_date) this.from_date = format(subDays(new Date(), 30),'yyyy-MM-dd');
            if(!this.to_date) this.to_date = format(new Date(), 'yyyy-MM-dd');
            url += `from_date=${this.from_date}&to_date=${this.to_date}`;
         }
         api.get(url).then(rsl => {
            if(rsl.status === 1) {
               this.omzet = [{value: (rsl.data.total || '0').toLocaleString('id-ID'),label:'Cash'}];
               this.product = [{value: rsl.data.product,label:'Produk'},{value: (rsl.data.item || 0),label:'Item'}];
               this.transaction = [{value: rsl.data.transaction,label:'Transaksi'},{value: rsl.data.customer,label:'Pelanggan'}];
            }
         })
         this.getDetail('sold');
      },
      getDetail(mod) {
         this.view_detail = mod;
         let url = `report/${mod}?`;
         if(this.type === 'daily') {
            url += `date=${this.date}`;
         } else {
            url += `from_date=${this.from_date}&to_date=${this.to_date}`;
         }
         api.get(url).then(rsl => {
            if(rsl.data) {
               this.list = rsl.data;
            } else this.list = [];
         })
      }
   }
}
</script>
<style scoped>
.flag-back { padding: 0 1em 1em; border-radius: 1em; background-color: #3338; }
.flag-back::before {
   content: '';
   position: absolute;
   left: 0;
   width: 100%;
   background: #6897d0;
   top: 0;
   height: 140px;
}
</style>
